import Tree, { TreeNode } from "rc-tree";
import { Key } from "rc-tree/lib/interface";
import { useState } from "react";
import SelectDown from "../icons/SelectDown";
import Member from "../member/Member";
import AddTeamButton from "../buttons/AddTeamButton";
import _ from "lodash";
import { useGetOrgTree } from "../api/common";
import { RequestOrgList, ResponseOrgData, User, TreeDataNode } from "../interfaces/OrgData";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import Loader from "../Loader";

interface Props {
  className?: string;
  requestOrgParent: RequestOrgList;
  // requestOrgChildren: RequestOrgList;
  selectedMember: TreeDataNode[];
  setSelectedMember: Function;
  checkedKeys: Key[];
  setCheckedKeys: Function;
}

const generateTreeDataDept = (orgData: AxiosResponse<ResponseOrgData>["data"]) => {
  const {
    data: {
      result: {
        dept: { data: dataDept },
      },
    },
  } = orgData;

  return dataDept.map(
    (item: {
      // gid: string;
      deptcode: string;
      // multiname: Record<string, string>[];
      deptname: string;
      parentdeptcode: string;
    }) => {
      // const language = _.find(item.multiname, ["language", "ko"]);

      return {
        // key: item.gid,
        key: item.deptcode,
        title: item.deptname,
        isLeaf: false,
        parentdeptcode: item.parentdeptcode,
        children: null,
      };
    },
  );
};

const generateTreeDataUser = (orgData: AxiosResponse<ResponseOrgData>["data"]) => {
  const {
    data: {
      result: {
        user: { data: dataUser },
      },
    },
  } = orgData;

  return dataUser.map((item: User) => {
    // const language = _.find(item.multiname, ["language", "ko"]);

    return {
      key: `${item.deptcode}|${item.bid}`,
      bid: item.bid,
      title: item.username,
      isLeaf: true,
      parentdeptcode: item.deptcode,
      position: item.position,
      team: item.deptname,
      url: item.picurl,
      children: null,
    };
  });
};

const generateTreeData = (orgData: AxiosResponse<ResponseOrgData>["data"]) => {
  const dataDept = generateTreeDataDept(orgData);
  const dataUser = generateTreeDataUser(orgData);
  return dataDept.concat(dataUser);
};

const flatToTree = (list: TreeDataNode[], parentdeptcode: string) => {
  const map: Record<string, number> = {};
  const roots = [];
  let node;
  let i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].key] = i;
    list[i].children = [];
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];

    if (node.parentdeptcode !== parentdeptcode) {
      // list[
      //   map[`${node.key.split(".")[0]}.${node.parentdeptcode}`]
      // ].children?.push(node);
      list[map[node.parentdeptcode]].children?.push(node);
    } else {
      roots.push(node);
    }
  }

  return roots;
};

const loop = (data: TreeDataNode[]) => {
  return data.map((item: TreeDataNode) => {
    if (item.children && item.children.length) {
      // if (!item.isLeaf) {
      return (
        <TreeNode
          key={item.key}
          title={
            <div className="flex flex-row items-center">
              <div className="flex-auto">{item.title}</div>
              <AddTeamButton className="flex-none hidden group-hover:inline-block" />
            </div>
          }
          className="flex flex-row px-2 items-center hover:bg-C-f1f3f6 active:bg-C-f1f3f6 h-7 group text-sm text-C-676767"
        >
          {loop(item.children)}
        </TreeNode>
      );
    }

    return (
      <TreeNode
        key={item.key}
        title={<Member name={item.title} position={item.position} team={item.team ?? ""} url={item.url ?? ""} />}
        className="flex flex-row px-2 items-center hover:bg-C-f1f3f6 active:bg-C-f1f3f6 h-14"
        isLeaf
      />
    );
  });
};

const getFlatMembers = (treeNode: TreeDataNode[]) => {
  const flatMembers: TreeDataNode[] = [];

  const traverse = (tree: TreeDataNode[]) => {
    tree.forEach(node => {
      if (node.isLeaf) {
        flatMembers.push(node);
      } else if (node.children?.length) {
        traverse(node.children);
      }
    });
  };

  traverse(treeNode);

  return flatMembers;
};

const OrgTree = ({
  className,
  requestOrgParent,
  // requestOrgChildren,
  selectedMember,
  setSelectedMember,
  checkedKeys,
  setCheckedKeys,
  ...props
}: Props) => {
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const orgFlatParent = useGetOrgTree(requestOrgParent);
  // const orgFlatChildren = useGetOrgTree(requestOrgChildren);

  // if (orgFlatParent.isLoading || orgFlatChildren.isLoading) {
  if (orgFlatParent.isLoading) {
    return <Loader className="rounded border border-solid border-C-c8cace basis-1/2 pt-0.5 min-h-[5.25rem] max-h-[13.908rem] md:max-h-[28.563rem]" />;
  }

  // if (orgFlatParent.isError || orgFlatChildren.isError) {
  if (orgFlatParent.isError) {
    if (orgFlatParent.error instanceof Error) {
      toast.error(orgFlatParent.error.message);
      return <></>;
    }

    // if (orgFlatChildren.error instanceof Error) {
    //   toast.error(orgFlatChildren.error.message);
    //   return <></>;
    // }
  }

  let orgFlatParentData: TreeDataNode[] = [];
  // orgFlatChildrenData: TreeDataNode[] = [];

  if (orgFlatParent.data) {
    orgFlatParentData = generateTreeData(orgFlatParent.data as ResponseOrgData);
  }

  // if (orgFlatChildren.data) {
  //   orgFlatChildrenData = generateTreeData(
  //     orgFlatChildren.data as ResponseOrgData
  //   );
  // }

  const finalOrgFlatData = _.uniqWith(
    // orgFlatParentData.concat(orgFlatChildrenData),
    orgFlatParentData,
    (a, b) => _.isEqual(a, b),
  );
  const finalOrgTreeData = flatToTree(finalOrgFlatData, orgFlatParentData[0].parentdeptcode);
  // console.log("🚀 ~ file: OrgTree.tsx:210 ~ finalOrgTreeData:", finalOrgTreeData);
  const sortedFinalOrgTreeData: TreeDataNode[] = _.cloneDeepWith(finalOrgTreeData, value => {
    const booleanArray: boolean[] = [];

    if (Array.isArray(value)) {
      (value as TreeDataNode[]).forEach(item => {
        booleanArray.push(item.isLeaf);
      });

      const duplicateFreeBooleanArray = _.uniq(booleanArray);

      if (duplicateFreeBooleanArray.length === 2) {
        return _.sortBy(value, [(o: TreeDataNode) => o.isLeaf === false]);
      }
    }
  }) as TreeDataNode[];
  // console.log("_.cloneDeepWith", sortedFinalOrgTreeData);
  const finalOrgFlatDataKeys = finalOrgFlatData.map(user => user.key);
  const otherOrgKeys = _.difference(checkedKeys, finalOrgFlatDataKeys);
  const otherOrgMembers = selectedMember.filter(member => !finalOrgFlatDataKeys.includes(member.key));

  return (
    <div
      className={`rounded border border-solid border-C-c8cace basis-1/2 flex flex-col min-h-[5.25rem] overflow-auto max-h-[13.908rem] md:max-h-[28.563rem] ${
        className ?? ""
      }`}
      {...props}
    >
      <Tree
        onExpand={keys => {
          setExpandedKeys(keys);
          setAutoExpandParent(false);
        }}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onCheck={keys => {
          const selectedKeys = [...otherOrgKeys, ...(keys as Key[])];
          // console.log("🚀 ~ file: OrgTree.tsx:270 ~ selectedKeys:", selectedKeys);
          // bid 중복 제거
          const removedDuplicatedKeys = _.uniqWith(selectedKeys, (a, b) => {
            if ((a as string).includes("|") && (b as string).includes("|")) {
              return (a as string).split("|")[1] === (b as string).split("|")[1];
            } else {
              return a === b;
            }
          });
          // console.log("🚀 ~ file: OrgTree.tsx:261 ~ removedDuplicatedKeys:", removedDuplicatedKeys);
          // const checkedMembers = finalOrgFlatData.filter((user) =>
          //   removedDuplicatedKeys.includes(user.key)
          // );
          // const checkedMembers = finalOrgFlatData.filter((user) =>
          //   selectedKeys.includes(user.key)
          // );
          // console.log(
          //   "🚀 ~ file: OrgTree.tsx:274 ~ checkedMembers:",
          //   checkedMembers
          // );
          // const removedDuplicatedMembers = checkedMembers.filter(
          //   (user) => !removedDuplicatedKeys.includes(user.parentdeptcode)
          // );
          // const removedDuplicatedMembers = checkedMembers.filter(
          //   (user) => !selectedKeys.includes(user.parentdeptcode)
          // );
          const duplicateFreeCheckedMembers = finalOrgFlatData
            .filter(user => removedDuplicatedKeys.includes(user.key))
            .filter(user => !removedDuplicatedKeys.includes(user.parentdeptcode));
          // console.log("🚀 ~ file: OrgTree.tsx:278 ~ duplicateFreeCheckedMembers:", duplicateFreeCheckedMembers);
          const flatMembers = getFlatMembers(duplicateFreeCheckedMembers);
          // console.log("🚀 ~ file: OrgTree.tsx:301 ~ flatMembers:", flatMembers);
          const uniqMembers = _.uniqWith(flatMembers, (a, b) => a.bid === b.bid);
          // console.log("uniqMembers", uniqMembers);
          const duplicatedMembers = _.xor(flatMembers, uniqMembers);
          // console.log("duplicatedMembers", duplicatedMembers);
          const finalRemovedDuplicatedMembers = _.xorWith(duplicateFreeCheckedMembers, duplicatedMembers, (a, b) => _.isEqual(a, b)).filter(
            user => !removedDuplicatedKeys.includes(user.parentdeptcode),
          );
          // console.log("🚀 ~ file: OrgTree.tsx:307 ~ finalRemovedDuplicatedMembers:", finalRemovedDuplicatedMembers);

          setCheckedKeys(removedDuplicatedKeys);
          // setCheckedKeys(selectedKeys);
          setSelectedMember([...otherOrgMembers, ...finalRemovedDuplicatedMembers]);
        }}
        checkedKeys={checkedKeys}
        switcherIcon={obj => {
          if (!obj.isLeaf) {
            return <SelectDown className={`${obj.expanded ? "" : "-rotate-90"}`} />;
          }
        }}
        checkable
        selectable={false}
        virtual
      >
        {loop(sortedFinalOrgTreeData)}
      </Tree>
    </div>
  );
};

export default OrgTree;
