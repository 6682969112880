import React, { useEffect, useRef, useState } from "react";
import config from "config";
import RoomClient from "RoomClient";
import ProfileImg from "components/ProfileImg";
import SubtitleView from "components/speechToText/SubtitleView";
import VirtualBgVideoView from "lib/virtualbg/core/components/VirtualBgVideoView";
import BigVideoView from "./BigVideoView";
import ScreenShareController from "./ScreenShareController";
import ButtonMic from "./buttons/ButtonMic";
import DocSharingHostViewRoot from "components/docSharing/root/DocSharingHostViewRoot";
import DocSharingClientViewRoot from "components/docSharing/root/DocSharingClientViewRoot";
import PipOnOffButton from "components/common/buttons/PipOnOffButton";
import ActiveSpeakerEffect from "./ActiveSpeakerEffect";
import FixedAspectRatioDivByHeight from "components/common/FixedAspectRatioDivByHeight";
import { PeerInfo } from "types/clientTypes";
import { withRoomContext } from "RoomContext";
import { selectIsCameRotate, selectVirtualBgType, selectVirtualBgUrl } from "store/settingSlice1";
import {
  selectIsShowDocSharingHost,
  selectIsShowDocSharingClient,
  selectIsShowScreenSharingHost,
  selectIsShowCaption,
  selectIsShowBottomVideoList,
  setIsShowScreenSharingHost,
  setIsShowScreenSharingClient,
} from "store/windowControlSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { isMobile, useMobileOrientation } from "react-device-detect";
import { useCanVirtualBg } from "hooks/useCanVirtualBg";

interface Props {
  id: string;
  peer: PeerInfo;
  stream: MediaStream | undefined;
  roomClient: RoomClient;
  hostName: string;
  hostPosition: string;
  isRemote: boolean;
  isScreen: boolean;
  screenShareStream: MediaStream | undefined;
}

const BigViewRoot = (props: Props) => {
  const { id, peer, stream, roomClient, hostName, hostPosition, isRemote, isScreen, screenShareStream } = props;
  const { t } = useTranslation();
  const [micEnabled, setMicEnabled] = useState(false);
  const [camEnabled, setCamEnabled] = useState(true);
  const isCamRotate = useSelector(selectIsCameRotate);
  const isShowCaption = useSelector(selectIsShowCaption);
  const isShowBottomVideoList = useSelector(selectIsShowBottomVideoList);
  const isShowDocSharingHost = useSelector(selectIsShowDocSharingHost);
  const isShowDocSharingClient = useSelector(selectIsShowDocSharingClient);
  const isShowScreenSharingHost = useSelector(selectIsShowScreenSharingHost);
  const virtualBgType = useSelector(selectVirtualBgType);
  const virtualBgUrl = useSelector(selectVirtualBgUrl);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { isPortrait } = useMobileOrientation();
  const canVirtualBg = useCanVirtualBg();
  const dispatch = useDispatch();

  useEffect(() => {
    if (peer) {
      setCamEnabled(peer.isCamEnable);
      setMicEnabled(peer.isMicEnable);
    }
  }, [peer, isRemote, isScreen, stream]);

  useEffect(() => {
    if (isShowDocSharingHost || isShowDocSharingClient) return;
    if (!videoRef.current) return;

    if (stream !== undefined && (camEnabled || isScreen)) {
      videoRef.current.srcObject = stream;
    } else {
      videoRef.current.srcObject = null;
    }
  }, [stream, camEnabled, isScreen, isShowDocSharingHost, isShowDocSharingClient]);

  useEffect(() => {
    console.log(`화면공유중인지 여부 판단. isRemote:${isRemote}, isScreen:${isScreen}`);
    dispatch(setIsShowScreenSharingHost(!isRemote && isScreen));
    dispatch(setIsShowScreenSharingClient(isRemote && isScreen));
  }, [dispatch, isRemote, isScreen]);

  const handleMicBtn = async (enable: boolean) => {
    const res = await roomClient.mute(!enable, isRemote, peer.socketId);
    if (!res) {
      toast.warn(t("msg.마이크 ON/OFF 권한없음"));
    }
  };

  const handleDumpRouter = () => {
    // roomClient.getResourceUsage();
    // if (roomClient.videoProducer) roomClient.dumpProducer(roomClient.videoProducer.id);
  };

  return (
    <div className="relative flex justify-center bg-[rgb(34, 34, 34)] h-full p-pt select-none">
      {!isShowDocSharingHost && !isShowDocSharingClient && (
        // 문서공유 호스트/클라이언트가 아닐때는 사회자영상 표시
        <FixedAspectRatioDivByHeight aspectRatio={1.6} divClassName="relative w-fit h-full overflow-x-hidden">
          {isRemote || virtualBgType === "none" || !canVirtualBg ? (
            <BigVideoView
              id={id}
              ref={videoRef}
              videoClassName={`${isMobile && isPortrait ? "h-auto" : "h-full"} bg-[#333] mx-auto`}
              videoStyle={isCamRotate && !isRemote ? { transform: "rotateY(180deg)" } : {}}
              isEnable={camEnabled || isScreen}
              refreshTrigger={isShowBottomVideoList}
            />
          ) : (
            <VirtualBgVideoView
              bgType={virtualBgType}
              bgUrl={virtualBgUrl}
              stream={stream}
              videoClassName={`bg-[#333] border-0 ${isMobile && isPortrait ? "w-full h-full border-red-400" : "w-fit h-full border-green-400"}`}
              isCamRotate={isCamRotate}
              configVideo={config.userMedia.video}
              configAspectRatio={config.aspectRatio}
              isEnable={camEnabled}
              isSmallSize={isShowBottomVideoList}
            />
          )}

          {(!isScreen || !isRemote) && (!camEnabled || !stream) && (
            <div className="absolute inset-0 flex justify-center items-center h-full">
              <div className="w-[10vh] h-[10vh] md:w-[16.5vh] md:h-[16.5vh] select-none">
                <ProfileImg picurl={peer?.userInfo?.picurl} borderWidth={1} />
              </div>
            </div>
          )}

          <div className="absolute top-4 left-0 px-4 h-10 flex z-10 items-center content-start select-none w-full">
            {!isMobile && (
              <div className="hidden md:flex min-w-min h-full rounded bg-gray-700/75 text-white items-center px-5 mr-2">
                <span className="max-w-[30rem] inline-block whitespace-nowrap overflow-hidden text-ellipsis">{hostName}</span>
                {hostPosition.length > 0 && <span className="ml-2 min-w-[1.8rem]">{hostPosition}</span>}
              </div>
            )}

            <div
              className="flex min-w-min h-full rounded bg-blue-500/75 text-white justify-center items-center px-6 shrink-0"
              onClick={handleDumpRouter}
            >
              <span>{t("home.사회자")}</span>
            </div>
          </div>

          <div className="absolute bottom-4 right-4 w-[2.25rem] h-[2.25rem] cursor-pointer z-30">
            <ButtonMic isEnable={micEnabled} onClick={() => handleMicBtn(!micEnabled)} />
          </div>

          {!isPortrait && <PipOnOffButton divStyle={{ right: 56 }} />}

          {isShowScreenSharingHost && <ScreenShareController screenShareStream={screenShareStream} />}

          <ActiveSpeakerEffect bid={peer.peerId} />
        </FixedAspectRatioDivByHeight>
      )}

      {(isShowDocSharingHost || isShowDocSharingClient) && (
        <div className="w-full grow bg-white flex justify-center overflow-hidden border-0 border-green-400">
          {isShowDocSharingHost ? <DocSharingHostViewRoot /> : <DocSharingClientViewRoot />}
        </div>
      )}

      {isShowCaption && (
        <div className="absolute bottom-0 mx-16 mb-6 z-30 text-[2.5rem]">
          <SubtitleView bidOrEmail={peer?.userInfo.bid} isRemote={isRemote} />
        </div>
      )}
    </div>
  );
};

export default React.memo(withRoomContext(BigViewRoot));
