import { memo, CSSProperties, useState, useEffect } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, areEqual } from "react-window";
import Member from "../member/Member";
import { useSearchUser } from "../api/common";
import { toast } from "react-toastify";
import _ from "lodash";
import { User } from "../interfaces/OrgData";
import { useInView } from "react-intersection-observer";
import Loader from "../Loader";
import { TreeDataNode } from "../interfaces/OrgData";
import { Key } from "rc-tree/lib/interface";

interface RowProps {
  style: CSSProperties;
  data: {
    userList: User[];
    fetchNextPage: Function;
    hasNextPage: boolean | undefined;
    setSelectedMember: Function;
    checkedKeys: Key[];
    setCheckedKeys: Function;
  };
  index: number;
}

interface Props {
  searchValue: string;
  setSelectedMember: Function;
  checkedKeys: Key[];
  setCheckedKeys: Function;
}

const Row = memo(function FnRow({ index, style, data }: RowProps) {
  const { ref, inView } = useInView();
  const { userList, fetchNextPage, hasNextPage, setSelectedMember, checkedKeys, setCheckedKeys } = data;
  const item = userList[index];
  const language = _.find(item.multiname, ["language", "ko"]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  return (
    <div
      style={style}
      className={`flex flex-row px-2 items-center hover:bg-C-f1f3f6 active:bg-C-f1f3f6 ${checkedKeys.includes(item.bid) ? "bg-C-f1f3f6" : ""}`}
      key={item.bid}
      onClick={() => {
        if (checkedKeys.includes(item.bid)) {
          setSelectedMember((prevSelectedMember: TreeDataNode[]) => prevSelectedMember.filter(member => member.key !== item.bid));
          setCheckedKeys((prevKeys: Key[]) => prevKeys.filter(key => key !== item.bid));
        } else {
          setSelectedMember((prevSelectedMember: TreeDataNode[]) => [
            ...prevSelectedMember,
            {
              key: item.bid,
              title: language ? language.name : "",
              isLeaf: true,
              parentdeptcode: item.deptcode,
              position: item.position ?? "",
              team: item.deptname ?? "",
              url: item.picurl ?? "",
              children: null,
            },
          ]);
          setCheckedKeys((prevKeys: Key[]) => [...prevKeys, item.bid]);
        }
      }}
    >
      <Member ref={userList.length - 1 === index ? ref : null} url={item.picurl ?? ""} name={language ? language.name : ""} team={item.deptname} />
    </div>
  );
}, areEqual);

const createItemData = (
  userList: User[],
  fetchNextPage: Function,
  hasNextPage: boolean | undefined,
  setSelectedMember: Function,
  checkedKeys: Key[],
  setCheckedKeys: Function,
) => ({
  userList,
  fetchNextPage,
  hasNextPage,
  setSelectedMember,
  checkedKeys,
  setCheckedKeys,
});

const SearchResult = ({ searchValue, setSelectedMember, checkedKeys, setCheckedKeys }: Props) => {
  const searchedUser = useSearchUser<{
    [x: string]: User[];
  }>(searchValue);
  const [userList, setUserList] = useState<User[]>([]);

  useEffect(() => {
    if (searchedUser.data?.pages.length) {
      const users: User[] = [];

      searchedUser.data.pages.forEach(page => {
        page?.data?.result.forEach((user: User) => users.push(user));
      });

      setUserList(users);
    } else {
      setUserList([]);
    }
  }, [searchedUser.data]);

  if (searchedUser?.isLoading && searchedUser.isFetching) {
    return <Loader className="border-t border-solid border-C-c8cace min-h-[5.25rem] flex-auto max-h-[11.438rem] md:max-h-[26.125rem]" />;
  }

  if (searchedUser?.isError) {
    if (searchedUser.error instanceof Error) {
      toast.error(searchedUser.error.message);
      return <></>;
    }
  }

  return (
    <div className="border-t border-solid border-C-c8cace min-h-[5.25rem] flex-auto max-h-[11.438rem] md:max-h-[26.125rem] overflow-hidden">
      {userList.length ? (
        <AutoSizer>
          {({ width, height }) => {
            const itemData = createItemData(
              userList,
              searchedUser.fetchNextPage,
              searchedUser.hasNextPage,
              setSelectedMember,
              checkedKeys,
              setCheckedKeys,
            );

            return (
              <FixedSizeList
                height={height}
                itemCount={userList.length}
                itemSize={56}
                width={width}
                itemData={itemData}
                itemKey={(index, data) => data.userList[index].bid}
              >
                {Row}
              </FixedSizeList>
            );
          }}
        </AutoSizer>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SearchResult;
