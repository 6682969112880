import Tree, { TreeNode } from "rc-tree";
import { Key } from "rc-tree/lib/interface";
import { useState } from "react";
import SelectDown from "../icons/SelectDown";
import Member from "../member/Member";
import AddTeamButton from "../buttons/AddTeamButton";
import _ from "lodash";
import { useGetOrgTree } from "../api/common";
import { RequestOrgList, ResponseOrgData, User, TreeDataNode } from "../interfaces/OrgData";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import Loader from "../Loader";

interface Props {
  className?: string;
  requestOrgParent: RequestOrgList;
  requestOrgChildren: RequestOrgList;
  selectedMember: TreeDataNode[];
  setSelectedMember: Function;
  checkedKeys: Key[];
  setCheckedKeys: Function;
}

const generateTreeDataDept = (orgData: AxiosResponse<ResponseOrgData>["data"]) => {
  const {
    data: {
      result: {
        dept: { data: dataDept },
      },
    },
  } = orgData;

  return dataDept.map((item: { deptcode: string; multiname: Record<string, string>[]; parentdeptcode: string }) => {
    const language = _.find(item.multiname, ["language", "ko"]);

    return {
      key: item.deptcode,
      title: language ? language.name : "",
      isLeaf: false,
      parentdeptcode: item.parentdeptcode,
      children: null,
    };
  });
};

const generateTreeDataUser = (orgData: AxiosResponse<ResponseOrgData>["data"]) => {
  const {
    data: {
      result: {
        user: { data: dataUser },
      },
    },
  } = orgData;

  return dataUser.map((item: User) => {
    const language = _.find(item.multiname, ["language", "ko"]);

    return {
      key: item.bid,
      title: language ? language.name : "",
      isLeaf: true,
      parentdeptcode: item.deptcode,
      position: item.position,
      team: item.deptname,
      url: item.picurl,
      children: null,
    };
  });
};

const generateTreeData = (orgData: AxiosResponse<ResponseOrgData>["data"]) => {
  const dataDept = generateTreeDataDept(orgData);
  const dataUser = generateTreeDataUser(orgData);
  return dataDept.concat(dataUser);
};

const flatToTree = (list: TreeDataNode[], parentdeptcode: string) => {
  const map: Record<string, number> = {};
  const roots = [];
  let node;
  let i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].key] = i;
    list[i].children = [];
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentdeptcode !== parentdeptcode) {
      list[map[node.parentdeptcode]].children?.push(node);
    } else {
      roots.push(node);
    }
  }

  return roots;
};

const loop = (data: TreeDataNode[]) => {
  return data.map((item: TreeDataNode) => {
    if (item.children && item.children.length) {
      return (
        <TreeNode
          key={item.key}
          title={
            <div className="flex flex-row items-center">
              <div className="flex-auto">{item.title}</div>
              <AddTeamButton className="flex-none hidden group-hover:inline-block" />
            </div>
          }
          className="flex flex-row px-2 items-center hover:bg-C-f1f3f6 active:bg-C-f1f3f6 h-7 group text-sm text-C-676767"
        >
          {loop(item.children)}
        </TreeNode>
      );
    }

    return (
      <TreeNode
        key={item.key}
        title={<Member name={item.title} position={item.position} team={item.team ?? ""} url={item.url ?? ""} />}
        className="flex flex-row px-2 items-center hover:bg-C-f1f3f6 active:bg-C-f1f3f6 h-14"
        isLeaf
      />
    );
  });
};

const OrgTree = ({
  className,
  requestOrgParent,
  requestOrgChildren,
  selectedMember,
  setSelectedMember,
  checkedKeys,
  setCheckedKeys,
  ...props
}: Props) => {
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const orgFlatParent = useGetOrgTree(requestOrgParent);
  const orgFlatChildren = useGetOrgTree(requestOrgChildren);

  if (orgFlatParent.isLoading || orgFlatChildren.isLoading) {
    return <Loader className="rounded border border-solid border-C-c8cace basis-1/2 pt-0.5 min-h-[5.25rem] max-h-[13.908rem] md:max-h-[28.563rem]" />;
  }

  if (orgFlatParent.isError || orgFlatChildren.isError) {
    if (orgFlatParent.error instanceof Error) {
      toast.error(orgFlatParent.error.message);
      return <></>;
    }

    if (orgFlatChildren.error instanceof Error) {
      toast.error(orgFlatChildren.error.message);
      return <></>;
    }
  }

  let orgFlatParentData: TreeDataNode[] = [],
    orgFlatChildrenData: TreeDataNode[] = [];

  if (orgFlatParent.data) {
    orgFlatParentData = generateTreeData(orgFlatParent.data as ResponseOrgData);
  }

  if (orgFlatChildren.data) {
    orgFlatChildrenData = generateTreeData(orgFlatChildren.data as ResponseOrgData);
  }

  const finalOrgFlatData = _.uniqWith(orgFlatParentData.concat(orgFlatChildrenData), (a, b) => _.isEqual(a, b));
  const finalOrgTreeData = flatToTree(finalOrgFlatData, orgFlatParentData[0].parentdeptcode);
  const finalOrgFlatDataKeys = finalOrgFlatData.map(user => user.key);
  const otherOrgKeys = _.difference(checkedKeys, finalOrgFlatDataKeys);
  const otherOrgMembers = selectedMember.filter(member => !finalOrgFlatDataKeys.includes(member.key));

  return (
    <div
      className={`rounded border border-solid border-C-c8cace basis-1/2 flex flex-col min-h-[5.25rem] overflow-auto max-h-[13.908rem] md:max-h-[28.563rem] ${
        className ?? ""
      }`}
      {...props}
    >
      <Tree
        onExpand={keys => {
          setExpandedKeys(keys);
          setAutoExpandParent(false);
        }}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onCheck={keys => {
          const selectedKeys = [...otherOrgKeys, ...(keys as Key[])];
          const checkedMembers = finalOrgFlatData.filter(user => selectedKeys.includes(user.key));
          const removedDuplicatedMembers = checkedMembers.filter(user => !selectedKeys.includes(user.parentdeptcode));

          setCheckedKeys(selectedKeys);
          setSelectedMember([...otherOrgMembers, ...removedDuplicatedMembers]);
        }}
        checkedKeys={checkedKeys}
        switcherIcon={obj => {
          if (!obj.isLeaf) {
            return <SelectDown className={`${obj.expanded ? "" : "-rotate-90"}`} />;
          }
        }}
        checkable
        selectable={false}
        virtual
      >
        {loop(finalOrgTreeData)}
      </Tree>
    </div>
  );
};

export default OrgTree;
