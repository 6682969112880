import { createGlobalState } from "react-use";
import { TreeDataNode } from "../interfaces/OrgData";

export interface Payload {
  sid: string | null;
  bid: string | null;
  commonDomain: string | undefined;
}

export const useGlobalAssignee = createGlobalState<TreeDataNode[]>([]);
export const useGlobalJWT = createGlobalState("");
export const useGlobalPayload = createGlobalState<Payload>({
  sid: null,
  bid: null,
  commonDomain: undefined,
});
export const useGlobalStorageId = createGlobalState("");
