import { useInfiniteQuery, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import request from "./request";
import { Payload, useGlobalJWT, useGlobalPayload } from "../hooks/useGlobalState";

type QueryKeyT = ["get" | "post", string, object | undefined];

interface GetInfinitePagesInterface<T> {
  data?: T & { eof?: boolean };
  cursorId?: number;
  hasNext?: boolean;
  results?: T[];
}

interface FetcherProps {
  queryKey: QueryKeyT;
  pageParam?: number;
  globalJWT?: string;
  globalPayload?: Payload;
}

const fetcher = ({ queryKey, pageParam, globalJWT, globalPayload }: FetcherProps) => {
  const [method, url, params] = queryKey;

  if (method === "get") {
    return request.get<AxiosResponse<any>>(url, { params: { ...params, pageParam } }).then(res => res.data);
  } else if (method === "post") {
    return request
      .post<AxiosResponse<any>>(
        url,
        url.startsWith(globalPayload?.commonDomain ?? "") ? { ...params, reqpage: pageParam } : { ...params, cursorId: pageParam },
        { headers: { Authorization: globalJWT ? `Bearer ${globalJWT}` : "" } },
      )
      .then(res => res.data);
  }
};

export const useLoadMore = <T>(method: "get" | "post", url: string | null, params?: object) => {
  const [globalJWT] = useGlobalJWT();
  const [globalPayload] = useGlobalPayload();

  const context = useInfiniteQuery<GetInfinitePagesInterface<T>, Error, GetInfinitePagesInterface<T>, QueryKeyT>(
    [method, url!, params],
    url?.startsWith(globalPayload.commonDomain ?? "")
      ? async ({ queryKey, pageParam = 1 }: FetcherProps) => fetcher({ queryKey, pageParam, globalJWT, globalPayload })
      : async ({ queryKey, pageParam }: FetcherProps) => fetcher({ queryKey, pageParam, globalJWT, globalPayload }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.data && !lastPage.data.eof) {
          return allPages.length + 1;
        } else if (lastPage.hasNext) {
          return lastPage.cursorId;
        } else {
          return false;
        }
      },
      enabled: !!url && !!globalJWT,
    },
  );

  return context;
};

export const useFetch = <T>(method: "get" | "post", url: string | null, params?: object, config?: UseQueryOptions<T, Error, T, QueryKeyT>) => {
  const [globalJWT] = useGlobalJWT();
  const [globalPayload] = useGlobalPayload();

  const context = useQuery<T, Error, T, QueryKeyT>([method, url!, params], async ({ queryKey }) => fetcher({ queryKey, globalJWT, globalPayload }), {
    enabled: !!url,
    ...config,
  });

  return context;
};

/* const useGenericMutation = <T, S>(
  func: (data: T | S) => Promise<AxiosResponse<S>>,
  url: string,
  params?: object,
  updater?: ((oldData: T, newData: S) => T) | undefined
) => {
  const queryClient = useQueryClient();

  return useMutation(func, {
    onMutate: async (data) => {
      await queryClient.cancelQueries([url, params]);

      const previousData = queryClient.getQueryData([url, params]);

      queryClient.setQueryData<T>([url, params], (oldData) => {
        return updater ? updater(oldData!, data as S) : (data as T);
      });

      return previousData;
    },
    onError: (err, _, context) => {
      queryClient.setQueryData([url, params], context);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries([url, params]);
    },
  });
}; */

/* export const useDelete = <T>(
  url: string,
  params?: object,
  updater?: (oldData: T, id: string | number) => T
) => {
  const [globalJWT] = useGlobalJWT();

  return useGenericMutation(
    (data) =>
      request.delete(url, {
        data,
        headers: { Authorization: globalJWT ? `Bearer ${globalJWT}` : "" },
      }),
    url,
    params,
    updater
  );
}; */

/* export const usePost = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T
) => {
  const [globalJWT] = useGlobalJWT();

  return useGenericMutation<T, S>(
    (data) => {
      console.log("data", data);
      return request.post(url, data, {
        headers: { Authorization: globalJWT ? `Bearer ${globalJWT}` : "" },
      });
    },
    url,
    params,
    updater
  );
}; */
export const usePost = <T>(url: string, options?: UseMutationOptions<AxiosResponse, unknown, T>) => {
  const [globalJWT] = useGlobalJWT();

  return useMutation<AxiosResponse<T>, unknown, T>(
    data =>
      request.post(url, data, {
        headers: { Authorization: globalJWT ? `Bearer ${globalJWT}` : "" },
      }),
    options,
  );
};

export const useUpdate = <T>(url: string, options?: UseMutationOptions<unknown, unknown, T>) => {
  const [globalJWT] = useGlobalJWT();

  return useMutation<unknown, unknown, T>(
    data =>
      request.patch(url, data, {
        headers: { Authorization: globalJWT ? `Bearer ${globalJWT}` : "" },
      }),
    options,
  );
};

export const useDelete = <T>(url: string, options?: UseMutationOptions<unknown, unknown, T>) => {
  const [globalJWT] = useGlobalJWT();

  return useMutation<unknown, unknown, T>(
    data =>
      request.delete(url, {
        data,
        headers: { Authorization: globalJWT ? `Bearer ${globalJWT}` : "" },
      }),
    options,
  );
};

/* export const useUpdate = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T
) => {
  const [globalJWT] = useGlobalJWT();

  return useGenericMutation<T, S>(
    (data) =>
      request.patch(url, data, {
        headers: { Authorization: globalJWT ? `Bearer ${globalJWT}` : "" },
      }),
    url,
    params,
    updater
  );
}; */
