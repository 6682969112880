import { TreeDataNode } from "../interfaces/OrgData";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, areEqual } from "react-window";
import { memo, CSSProperties } from "react";
import _ from "lodash";
import Member from "../member/Member";
import { useTranslation } from "react-i18next";

interface Props {
  className: string;
  selectedMember: TreeDataNode[];
  setSelectedMember: Function;
  setCheckedKeys: Function;
}

interface RowProps {
  style: CSSProperties;
  data: {
    selectedMember: TreeDataNode[];
    setSelectedMember: Function;
    setCheckedKeys: Function;
  };
  index: number;
}

const Row = memo(function FnRow({ index, style, data }: RowProps) {
  const { selectedMember, setSelectedMember, setCheckedKeys } = data;
  const item = selectedMember[index];

  return (
    <div style={style} className="flex flex-row px-2 items-center hover:bg-C-f1f3f6 active:bg-C-f1f3f6" key={item.key}>
      <Member
        url={item.url ? item.url : !item.isLeaf ? "team" : ""}
        name={item.title ?? ""}
        position={item.position ?? ""}
        team={item.team ?? ""}
        showDelete
        onClickDelete={() => {
          const filteredDeletedMembers = selectedMember.filter(member => member.key !== item.key);
          const filteredDeletedKeys = filteredDeletedMembers.map(member => member.key);

          setSelectedMember(filteredDeletedMembers);
          setCheckedKeys(filteredDeletedKeys);
        }}
      />
    </div>
  );
}, areEqual);

const createItemData = _.memoize((selectedMember: TreeDataNode[], setSelectedMember: Function, setCheckedKeys: Function) => ({
  selectedMember,
  setSelectedMember,
  setCheckedKeys,
}));

const getCountMembers = (treeDataNode: TreeDataNode[]) => {
  let count = 0;

  const loop = (loopData: TreeDataNode[]) => {
    loopData.forEach(node => {
      if (node.isLeaf) {
        count++;
      } else {
        if (!!node.children) {
          loop(node.children);
        }
      }
    });
  };

  loop(treeDataNode);

  return count;
};

const SelectedBox = ({ className, selectedMember, setSelectedMember, setCheckedKeys }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={`rounded border border-solid border-C-c8cace basis-1/2 flex flex-col ${className}`}>
      <div className="text-sm text-C-676767 h-[2.375rem] leading-[2.375rem] pl-3 flex-none">{`${t("adduser.선택")}: ${getCountMembers(
        selectedMember,
      )}${t("adduser.명")}`}</div>
      <div className="border-t border-solid border-C-c8cace min-h-[5.25rem] overflow-auto flex-auto max-h-[11.438rem] md:max-h-[26.125rem]">
        <AutoSizer>
          {({ width, height }) => {
            const itemData = createItemData(selectedMember, setSelectedMember, setCheckedKeys);

            return (
              <FixedSizeList
                height={height}
                itemCount={selectedMember?.length}
                itemSize={56}
                width={width}
                itemData={itemData}
                itemKey={(index, data) => data.selectedMember[index].key}
              >
                {Row}
              </FixedSizeList>
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
};

export default SelectedBox;
