import React, { useEffect, useRef, useCallback, useState } from "react";
import RoomClient from "RoomClient";
import MemoRow from "./MemoRow";
import SpeechToTextRow from "./SpeechToTextRow";
import MemoInput from "./MemoInput";
import SwitchComp from "components/Switch";
import SelectListbox2 from "components/SelectListbox2";
import i18next from "i18next";
import CloseButton from "components/common/buttons/CloseButton";
import { withRoomContext } from "RoomContext";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectAllSttData, selectAllMemoData, setLastReadCnt } from "store/noteSlice";
import { selectIsSttTranslation, setIsSttTranslation, selectRoomSetting, setRoomSetting } from "store/roomInfoSlice";
import { Language, MemoData, SpeechToTextData } from "types/commonTypes";
import { selectSttFromLanguage, selectSttToLanguage, setSttFromLanguage, setSttToLanguage } from "store/settingSlice1";
import { options_lang } from "components/setting/Setting";
import { Value } from "components/common/interfaces/TypeSelectListbox";
import { isMobile } from "react-device-detect";
import { ko, ja, enUS } from "date-fns/locale";

interface PropsDefault {
  roomClient: RoomClient;
  onCloseView: () => void;
  buttonRef: React.RefObject<HTMLDivElement>;
}

enum Menu {
  STT = "stt",
  MEMO = "memo",
}

const NoteMain = (props: PropsDefault) => {
  const { onCloseView, roomClient, buttonRef } = props;
  const { t } = useTranslation();
  const [menu, setMenu] = useState<Menu>(Menu.STT);
  const [selectedOptionFromLang, setSelectedOptionFromLang] = useState<Value>(options_lang[0]);
  const [selectedOptionToLang, setSelectedOptionToLang] = useState<Value>(options_lang[0]);
  const [locale, setLocale] = useState<Locale>();
  const rootDivRef = useRef<HTMLDivElement>(null);
  const sttData = useSelector(selectAllSttData);
  const memoData = useSelector(selectAllMemoData);
  const sttFromLanguage = useSelector(selectSttFromLanguage);
  const sttToLanguage = useSelector(selectSttToLanguage);
  const isTranslation = useSelector(selectIsSttTranslation);
  const roomSetting = useSelector(selectRoomSetting);
  const messagesEndRef = React.createRef<HTMLDivElement>();
  const dispatch = useDispatch();

  const scrollToBottom = useCallback(
    (behavior: ScrollBehavior) => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior });
      }
    },
    [messagesEndRef],
  );

  useEffect(() => {
    const currentLng = i18next.language === "ko-KR" ? ko : i18next.language === "ja-JP" ? ja : enUS;
    setLocale(currentLng ?? ko);
  }, []);

  useEffect(() => {
    options_lang.forEach(item => {
      if (item.id === sttFromLanguage) {
        setSelectedOptionFromLang(item);
      }
    });
  }, [sttFromLanguage]);

  useEffect(() => {
    options_lang.forEach(item => {
      if (item.id === sttToLanguage) {
        setSelectedOptionToLang(item);
      }
    });

    roomClient.sendTranslationConfig(isTranslation, sttToLanguage);
  }, [isTranslation, roomClient, sttToLanguage]);

  useEffect(() => {
    scrollToBottom("auto");

    const handleClickOutside = (ev: Event) => {
      if (buttonRef) {
        if (buttonRef.current?.contains(ev.target as Node)) {
          return;
        }
      }
      if (rootDivRef.current && !rootDivRef.current.contains(ev.target as Node)) {
        onCloseView();
      }
    };

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [buttonRef, onCloseView, rootDivRef, scrollToBottom]);

  useEffect(() => {
    scrollToBottom("smooth");
    dispatch(setLastReadCnt(sttData.length));
  }, [sttData, dispatch, scrollToBottom]);

  const handleClose = () => {
    onCloseView();
  };

  const handleSendMemo = (newMemo: MemoData) => {
    // dispatch(addMemoData(newMemo));
    roomClient.sendMemo(newMemo);
  };

  const handleChangeSttAutoSave = (checked: boolean) => {
    const newInfo = { ...roomSetting, isAutoSaveNote: checked };
    dispatch(setRoomSetting(newInfo));

    roomClient.sendChangeRoomSetting(newInfo, (result: boolean) => {
      // console.log('changeRoomSetting. res', result);
    });
  };

  const handleChangeTranslationEnable = (checked: boolean) => {
    dispatch(setIsSttTranslation(checked));
    roomClient.sendTranslationConfig(checked, sttToLanguage);
  };

  const handleChangeFromLanguage = (v: Value) => {
    setSelectedOptionFromLang(v);
    dispatch(setSttFromLanguage(v.id as Language));
  };

  const handleChangeToLanguage = (v: Value) => {
    setSelectedOptionToLang(v);
    dispatch(setSttToLanguage(v.id as Language));
  };

  const handleTestKo = () => {
    const today = new Date();
    const newStt: SpeechToTextData = {
      roomId: roomClient.roomId,
      userInfo: { isInsider: true, bidOrEmail: roomClient.userInfo.bid },
      text: "한글 테스트입니다. \n조금 긴 문장이 어떻게 표시되는지 확인합니다.\n세 번째줄입니다.",
      // text: '한글 테스트입니다.',
      time: today.toISOString(),
      isLocal: true,
      language: Language.KO,
      isFinal: true,
    };

    roomClient.sendSpeechToText(newStt);
  };

  const handleTestEn = () => {
    const today = new Date();
    const newStt: SpeechToTextData = {
      roomId: roomClient.roomId,
      userInfo: { isInsider: true, bidOrEmail: roomClient.userInfo.bid },
      text: "This is english test.",
      time: today.toISOString(),
      isLocal: true,
      language: Language.EN,
      isFinal: true,
    };

    roomClient.sendSpeechToText(newStt);
  };

  return (
    <div
      className={`${
        isMobile ? "h-full" : "min-h-[17rem] sm:w-[26.5rem] sm:h-full max-h-[calc(100%-50px)] sm:max-h-[70%] bottom-1.5 right-0"
      } w-full z-20 flex flex-col shadow bg-[#333]`}
      ref={rootDivRef}
    >
      {/* TitleBar + 닫기버튼 */}
      <div className="flex w-full h-[4.125rem] min-h-[4.125rem] justify-start items-center">
        <CloseButton width={24} height={24} className="ml-5" classNameSVG="stroke-C-c8cace hover:stroke-C-1f62b9" onClick={handleClose} />
        <div className="ml-4 text-lg font-bold leading-6 text-white select-none">{t("home.회의록")}</div>
      </div>

      <div className="px-[1.25rem] w-full min-h-[1.875rem] flex items-center text-[#86878b] text-sm font-bold">
        <div className="h-full flex flex-col justify-between select-none" onClick={() => setMenu(Menu.STT)}>
          <div
            className={`px-2 active:text-[#4495ff] hover:text-[#4495ff] cursor-pointer ${menu === Menu.STT ? "text-[#4495ff]" : "text-[#86878b]"}`}
          >
            {t("note.음성번역 기록")}
          </div>
          <div className="h-1 flex items-end">
            {menu === Menu.STT ? <div className="bg-[#4495ff] w-full h-1" /> : <div className="bg-[#86878b] w-full h-px" />}
          </div>
        </div>
        <div className="h-full flex flex-col justify-between select-none" onClick={() => setMenu(Menu.MEMO)}>
          <div
            className={`px-2 active:text-[#4495ff] hover:text-[#4495ff] cursor-pointer ${menu === Menu.MEMO ? "text-[#4495ff]" : "text-[#86878b]"}`}
          >
            {t("note.회의 메모")}
          </div>
          <div className="h-1 flex items-end">
            {menu === Menu.MEMO ? <div className="bg-[#4495ff] w-full h-1" /> : <div className="bg-[#86878b] w-full h-px" />}
          </div>
        </div>
      </div>

      {/* Speech To Text 내용 */}
      {menu === Menu.STT && (
        <>
          <div className="text-white px-6 mt-3 text-sm">
            <div className="flex justify-between h-10 items-center">
              <span className="select-none">{t("note.회의록 자동으로 기록하기")}</span>
              <SwitchComp enabled={roomSetting.isAutoSaveNote} onChange={checked => handleChangeSttAutoSave(checked)} />
            </div>

            <div className="flex justify-between h-10 items-center">
              <span className="select-none">{t("note.음성번역")}</span>

              {process.env.REACT_APP_NODE_ENV === "development" && (
                <div>
                  <button className="border rounded px-4 bg-gray-400" onClick={handleTestKo}>
                    테스트KO
                  </button>
                  <button className="border rounded px-4 bg-gray-400 ml-1" onClick={handleTestEn}>
                    테스트EN
                  </button>
                </div>
              )}
              <SwitchComp enabled={isTranslation} onChange={checked => handleChangeTranslationEnable(checked)} />
            </div>

            <div className="flex justify-between h-10 leading-[2.5rem] select-none">
              <span className="select-none">{t("note.발화 언어")}</span>
              <div className="ml-4 mt-2 h-8 w-[10.375rem]">
                <SelectListbox2
                  options={options_lang}
                  value={selectedOptionFromLang}
                  onChange={v => handleChangeFromLanguage(v)}
                  optionsHeight={40}
                />
              </div>
            </div>

            <div className="flex justify-between h-10 leading-[2.5rem] select-none">
              <span className="select-none">{t("note.번역 후 언어")}</span>
              <div className="ml-4 mt-2 h-8 w-[10.375rem]">
                <SelectListbox2 options={options_lang} value={selectedOptionToLang} onChange={v => handleChangeToLanguage(v)} optionsHeight={40} />
              </div>
            </div>
          </div>
          <div className="w-full h-px bg-[#555] mt-2" /> {/* 구분선 */}
          <div className="h-full w-full p-px flex flex-col overflow-y-hidden">
            <div className="overflow-y-auto grow ">
              {sttData.map((item: SpeechToTextData, idx) =>
                item.isFinal ? (
                  <SpeechToTextRow
                    key={item.userInfo.bidOrEmail + item.time + idx.toString()}
                    sttData={item}
                    localName={roomClient.userInfo.username}
                    onTransText={() => scrollToBottom("smooth")}
                    locale={locale}
                  />
                ) : (
                  <></>
                ),
              )}
              <div ref={messagesEndRef} />
            </div>
          </div>
        </>
      )}

      {/* 회의 메모 내용 */}
      {menu === Menu.MEMO && (
        <>
          <div className="w-full h-px bg-[#555] mt-2" /> {/* 구분선 */}
          <div className="grow h-full w-full p-px flex flex-col overflow-y-hidden">
            <div className="overflow-y-auto grow ">
              {memoData.map((item: MemoData) => (
                <MemoRow
                  key={item.userInfo.bidOrEmail + item.time + Math.random()}
                  memoData={item}
                  localName={roomClient.userInfo.username}
                  locale={locale}
                />
              ))}
              <div ref={messagesEndRef} />
            </div>

            <div className="p-0">
              <MemoInput roomId={roomClient.roomId} onSend={handleSendMemo} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withRoomContext(NoteMain);
