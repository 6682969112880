import { IInnerAttendee, IOuterAttendee } from "api/manager/invite";
import { IDocSharingPageInfo } from "components/docSharing/docSharingType";
import { TransportOptions, RtpParameters, MediaKind } from "mediasoup-client/lib/types";

export type AuthUserInfo = {
  bid: string;
  username: string;
  deptname?: string;
  position?: string;
  picurl: string;
  compname?: string;
  userid?: string;
  email?: string;
  gid?: string;
  language?: string;
};

export type SimpleUserInfo = {
  isInsider: boolean;
  bidOrEmail: string;
  username?: string;
  picurl?: string;
};

export type OutSiderInfo = {
  name: string;
  email: string;
};

export interface AppData {
  socketId: string;
  isHost: boolean;
  isScreen: boolean;
}

export interface ConsumeForCallback {
  id: string;
  producerId: string;
  kind: MediaKind;
  rtpParameters: RtpParameters;
  serverConsumerId: string;
  appData: any;
  socketId: string;
}

export interface callbackConsume {
  params: ConsumeForCallback;
  error: Error;
}

export interface callbackTransport {
  params: TransportOptions;
  error: Error;
}

export interface callbackMute {
  result: boolean;
}

export interface callbackTransportProduce {
  id: string;
  producersExist: boolean;
}

export interface CallbackCloseProducer {
  (arg0: { currentCnt: number }): void;
}

export interface IDocSharingHost {
  socketId: string;
  info: IDocSharingPageInfo;
}

export enum ChatMsgType {
  CHAT = 0,
  NOTI,
  INVITE,
}

export interface ChatData {
  roomId: number;
  userInfo: SimpleUserInfo;
  text: string;
  time?: string;
  isLocal?: boolean;
  emoticon?: string;
  targetSocket?: string;
  targetName?: string;
  msgType?: ChatMsgType;
  serverTime?: string;
}

export enum Language {
  KO = "ko-KR",
  EN = "en-US",
  JP = "ja-JP",
  Language = "Language",
}

export interface SpeechToTextData {
  roomId: number;
  userInfo: SimpleUserInfo;
  text: string;
  time?: string;
  isLocal?: boolean;
  language: Language;
  isFinal?: boolean;
  translations?: Translation[];
}

export interface MemoData {
  roomId: number;
  userInfo: SimpleUserInfo;
  text: string;
  time?: string;
  isLocal?: boolean;
}

export interface JoinRequestRes {
  roomId: number;
  bid: string;
  result: boolean;
}

export interface IRoomDto {
  id: number;
  accessCode: string;
  title: string;
  creator: string;
  // setting: RoomSetting;
  innerAttendees: IInnerAttendee[];
  outerAttendees: IOuterAttendee[];
}

export interface RoomSetting {
  isPrivate: boolean;
  password: string;
  isMicOnStartUp: boolean;
  isCamOnStartUp: boolean;
  isSelfMicOn: boolean;
  hasWaitingRoom: boolean;
  isSttEnable: boolean;
  isAutoSaveNote: boolean;
  isDocSharingOnlyHost: boolean;
  isWhiteBoardOnlyHost: boolean;
}

export interface IRoomInfo {
  hostBid: string;
  isHostMode: boolean;
  curPeerCnt: number;
  setting?: RoomSetting;
}

export interface IChangeRoomHost {
  roomId: number;
  hostBid: string;
}

export interface IJwt {
  roomId: number;
  jwt: string;
}

export interface IAzureTranslation {
  translations: Translation[];
}

export interface Translation {
  text: string;
  to: string;
}

export interface IDuplicatePeerInfo {
  peerId: string;
  os: string;
  browser: string;
  ip: string;
}
