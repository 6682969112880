import axios, { AxiosRequestConfig } from "axios";

const axiosInstance = axios.create();

const request = {
  get: <T>(url: string, config?: AxiosRequestConfig<any>) => axiosInstance.get<string, T>(url, config),
  post: <T>(url: string, data: unknown, config?: AxiosRequestConfig<any>) => axiosInstance.post<string, T>(url, data, config),
  patch: <T>(url: string, data: unknown, config: AxiosRequestConfig<any>) => axiosInstance.patch<string, T>(url, data, config),
  put: <T>(url: string, data: unknown, config: AxiosRequestConfig<any>) => axiosInstance.put<string, T>(url, data, config),
  delete: <T>(url: string, config?: AxiosRequestConfig<any>) => axiosInstance.delete<string, T>(url, config),
};

export default request;
