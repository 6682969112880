import OrgTree from "./OrgTree";
import SelectedBox from "./SelectedBox";
import { TreeDataNode } from "../interfaces/OrgData";
import { Key } from "rc-tree/lib/interface";
import { useGlobalPayload } from "../hooks/useGlobalState";

interface OrgTreeBoxProps {
  gid: string;
  selectedMember: TreeDataNode[];
  setSelectedMember: Function;
  checkedKeys: Key[];
  setCheckedKeys: Function;
}

const OrgTreeBox = ({ gid, selectedMember, setSelectedMember, checkedKeys, setCheckedKeys }: OrgTreeBoxProps) => {
  const [globalPayload] = useGlobalPayload();
  const requestOrgParent = {
    cmd: "1103",
    sid: globalPayload.sid as string,
    what: [{ gid }],
    kind: "DEPT",
    include: "DEPTUSER",
  };
  const requestOrgChildren = {
    ...requestOrgParent,
    kind: "CHILDREN",
  };

  return (
    <div className="flex flex-col md:flex-row md:gap-x-4 h-full">
      <OrgTree
        className="flex-auto"
        requestOrgParent={requestOrgParent}
        requestOrgChildren={requestOrgChildren}
        selectedMember={selectedMember}
        setSelectedMember={setSelectedMember}
        checkedKeys={checkedKeys}
        setCheckedKeys={setCheckedKeys}
      />
      <SelectedBox
        selectedMember={selectedMember}
        setSelectedMember={setSelectedMember}
        setCheckedKeys={setCheckedKeys}
        className="mt-3 md:mt-0 flex-auto"
      />
    </div>
  );
};

export default OrgTreeBox;
