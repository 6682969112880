import { Tab } from "@headlessui/react";

interface Props {
  tabList: {
    id: string;
    name: string;
  }[];
  tabPanels: {
    id: string;
    content: any;
  }[];
  className?: string;
  panelClassName?: string;
}

const Tabs = ({ tabList, tabPanels, className, panelClassName }: Props) => {
  return (
    <Tab.Group as="div" className={className}>
      <div>
        <Tab.List as="ul" className="inline-flex flex-row flex-nowrap flex-none border-b border-solid border-C-dfe2e8 cursor-pointer h-[1.875rem]">
          {tabList.map(tab => (
            <Tab
              key={tab.id}
              as="li"
              className={({ selected }) =>
                `px-3 h-[1.875rem] hover:text-C-1f62b9 focus:outline-none ${
                  selected ? "text-C-1f62b9 font-bold border-b-4 border-solid border-C-1f62b9" : "text-C-86878b"
                }`
              }
            >
              {tab.name}
            </Tab>
          ))}
        </Tab.List>
      </div>
      <Tab.Panels className={`flex-auto ${panelClassName ? panelClassName : ""}`}>
        {tabPanels.map(tabPanel => (
          <Tab.Panel className="h-full" key={tabPanel.id}>
            {tabPanel.content}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default Tabs;
