import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getSpeakers, getSelectedSpeaker } from "../../../store/settingSlice1";
import { Value } from "../../common/interfaces/TypeSelectListbox";
import SelectListbox2 from "../../SelectListbox2";
import RoomClient from "../../../RoomClient";
import { withRoomContext } from "../../../RoomContext";
import { useTranslation } from "react-i18next";
import { isSafari, isMobile } from "react-device-detect";

interface Step1Props {
  roomClient: RoomClient;
  onSuccess: (val: Value) => void;
  onFail: () => void;
}

const Step1Speaker = (props: Step1Props) => {
  const { onSuccess, onFail } = props;
  const { t } = useTranslation();
  const [selectedOptionSpk, setSelectedOptionSpk] = useState<Value>({ id: "", name: "" });
  const [options_spk, setOptionSpk] = useState<Value[]>([]);
  const speakers = useSelector(getSpeakers);
  const selectedSpeakerId = useSelector(getSelectedSpeaker);
  // const [audio] = useState<any>(new Audio('./sound/digital-effect.mp3'));
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  // const [playerStream, setPlayerStream] = useState<MediaStream>();
  // const dispatch = useDispatch();

  // let isEnd = false;
  const [isEnd, setIsEnd] = useState(false);
  let ctx;

  const bufferLength = 2048;
  const [dataArray] = useState(new Uint8Array(bufferLength));
  const [audioContext] = useState(new AudioContext());
  // const [source, setSource] = useState<MediaElementAudioSourceNode>();
  const [analyser] = useState<AnalyserNode>(audioContext.createAnalyser());
  // const [gainNode] = useState(audioContext.createGain());
  const [audioEle, setAudioEle] = useState<HTMLAudioElement>(new Audio()); //'./sound/digital-effect.mp3'
  const autioFilePath = "./sound/digital-effect.mp3";

  useEffect(() => {
    console.log("Step1 실행");
    return () => {
      setIsEnd(true);
      audioEle?.pause();
      console.log("Step1 종료");
    };
  }, [audioEle]);

  // useEffect(() => {
  //   if (!source) {
  //     console.log('이건 한번만 실행되어야 한다.!!!!!');
  //     connectRes();
  //   }
  // }, [source]);

  // const connectRes = () => {
  //   // tempAudio.loop = true;
  //   const s = audioContext.createMediaElementSource(tempAudio);
  //   setSource(s);

  //   const panner = audioContext.createStereoPanner();
  //   panner.pan.value = -1;

  //   const dest = audioContext.createMediaStreamDestination();
  //   setDestination(dest);

  //   s.connect(analyser).connect(panner).connect(dest);

  //   analyser.fftSize = bufferLength;
  //   analyser.getByteFrequencyData(dataArray);
  //   draw();
  //   tempAudio.play();
  // };

  useEffect(() => {
    // console.log('setting speakers', Array.from(speakers.values()));
    const newSpks: Value[] = [];

    speakers.forEach((values, key) => {
      const val = { id: key, name: values.label };
      newSpks.push(val);
      if (key === selectedSpeakerId) {
        setSelectedOptionSpk(val);
      }
    });

    setOptionSpk(newSpks);
  }, [selectedSpeakerId, speakers, t]);

  useEffect(() => {
    if (isSafari || isMobile) {
      if (!audioRef.current) return;
      setAudioEle(audioRef.current);

      audioEle.pause();
      audioEle.currentTime = 0;
      audioEle.autoplay = true;
      audioRef.current.src = autioFilePath;
      audioContext.resume();

      return;
    }

    if (!selectedOptionSpk.id) return;
    console.log("###### 스피커 지정됨 ######", selectedOptionSpk.name, selectedOptionSpk.id);

    audioEle.pause();
    audioEle.currentTime = 0;
    audioEle.autoplay = true;
    // const testAudio = new Audio();

    const audio: any = audioRef.current;
    if (!audio) {
      console.log("Step1Speaker. audio not found!");
      return;
    }

    if (selectedOptionSpk.id && typeof audio.setSinkId === "function") {
      console.log("setSinkId 호출!", selectedOptionSpk.name);

      if (!audioRef.current) return;
      audioRef.current.src = "";
      audioRef.current.srcObject = null;
      // audioContext.suspend();
      // source?.disconnect();

      audio.setSinkId(selectedOptionSpk.id).then(() => {
        // console.log("setSinkId 성공!@!");
        if (!audioRef.current) return;

        audioRef.current.src = autioFilePath;
        audioContext.resume();

        // let sourceNode = audioContext.createMediaElementSource(audioRef.current);
        // setSource(sourceNode);
        // let sourceNode;
        // if (!source) {
        //   console.log('###### 새로 audioSourceNode 생성 #####');
        //   sourceNode = audioContext.createMediaElementSource(audioRef.current);
        //   setSource(sourceNode);
        // } else {
        //   console.log('###### 만들어진 audioSourceNode 사용 #####');
        //   sourceNode = source;
        // }

        // sourceNode.connect(analyser).connect(audioContext.destination);

        // analyser.fftSize = bufferLength;
        // analyser.getByteFrequencyData(dataArray);
        // draw();
        // audioRef.current.play();
        setAudioEle(audioRef.current);
      });
    } else {
      console.log("Step1Speaker. setSinkId not support!");
    }
  }, [audioContext, audioEle, selectedOptionSpk]);

  const handleClick = (isYes: boolean) => {
    if (isYes) {
      // roomClient.changeAudioOutputDevice(selectedOptionSpk.id);
      onSuccess(selectedOptionSpk);
    } else {
      if (!isSafari) {
        const values = Array.from(speakers.values());
        let curIdx = values.findIndex(val => val.deviceId === selectedOptionSpk.id);
        console.log("현재 선택된 idx", curIdx);

        if (++curIdx >= values.length) curIdx = 0;
        setSelectedOptionSpk(options_spk[curIdx]);
      } else {
        onFail();
      }
    }
  };

  const draw = () => {
    if (!isEnd) {
      // console.log('tick~~~', dataArray.length);
      animationLooper();
      analyser.getByteTimeDomainData(dataArray);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      requestAnimationFrame(draw);
    }
  };

  const animationLooper = () => {
    // todo: canvas에 그린다.
    if (canvasRef.current) {
      ctx = canvasRef.current?.getContext("2d");
      if (!ctx) return;

      ctx.fillStyle = "#555";
      ctx.fillRect(0, 0, 300, 15);

      let w = 0;
      ctx.fillStyle = "#4495ff";
      for (var i = 0; i < bufferLength; i++) {
        // w = dataArray[i];
        w = (dataArray[i] / 255) * canvasRef.current.width;
        ctx?.fillRect(0, 0, w, 15);
      }
    }
  };

  return (
    <div className="flex flex-col items-center">
      <audio ref={audioRef} loop />

      <div className="text-sm text-center text-[#c8cace]">{t("setting.스피커 테스트 중...")}</div>
      <div className="text-base text-center font-bold mt-2">{t("setting.벨소리가 들립니까?")}</div>
      <div className="mt-8 flex justify-center items-center">
        <button className="rounded flex justify-center items-center w-24 h-8 bg-[#1f62b9] hover:bg-[#3f82d9]" onClick={() => handleClick(true)}>
          {t("예")}
        </button>
        <button
          className="rounded border border-zinc-400 flex justify-center items-center w-24 h-8 mr-1 hover:bg-zinc-400 ml-6"
          onClick={() => handleClick(false)}
        >
          {t("아니오")}
        </button>
      </div>

      <div className="px-6 w-full mt-[2.25rem] mb-4">
        <table className="table w-full">
          <tbody className="text-sm">
            <tr className="h-8 border-b-4 border-b-[#0000]">
              <td className="w-1 h-full pr-4 whitespace-nowrap leading-8">
                <span>{t("스피커")}</span>
              </td>
              <td className="relative z-20">
                <div className="absolute top-pt left-0 w-full">
                  {!isSafari ? (
                    <SelectListbox2 options={options_spk} value={selectedOptionSpk} onChange={v => setSelectedOptionSpk(v)} optionsHeight={40} />
                  ) : (
                    <div className="border border-solid border-[#86878b] bg-[#555] h-8 rounded text-white pl-3 pr-2 text-sm flex flex-row items-center cursor-default">
                      {t("시스템 기본")}
                    </div>
                  )}
                </div>
              </td>
            </tr>
            <tr className="h-8 border-b-1 border-b-[#0000]">
              <td className="w-1 h-full pr-4 whitespace-nowrap leading-8">
                <span>{t("setting.출력레벨")}</span>
              </td>
              <td className="leading-8 flex items-center h-8">
                <div className="grow relative ml-[0rem] h-2 bg-[#555]">
                  <canvas ref={canvasRef} className="w-full h-full absolute top-0 left-0" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default withRoomContext(Step1Speaker);
