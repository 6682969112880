import { useFetch, useLoadMore } from "./CRUD";
import { RequestOrgList, RequestUser, ResponseUser } from "../interfaces/OrgData";
import { useGlobalPayload, useGlobalStorageId } from "../hooks/useGlobalState";
import { ResponseStorageEndpoint } from "../interfaces/File";

export const useGetOrgTree = (requestData: RequestOrgList) => {
  const [globalPayload] = useGlobalPayload();
  const context = useFetch("post", `${globalPayload.commonDomain}/api/v1/crs-id-hrs/orgs/list`, requestData);

  return context;
};

export const useSearchUser = <T>(keyword: string | null) => {
  const [globalPayload] = useGlobalPayload();
  const context = useLoadMore<T>("post", keyword ? `${globalPayload.commonDomain}/api/v1/crs-id-hrs/users/search` : null, {
    cmd: "1102",
    sid: globalPayload.sid,
    kind: "ALL",
    fields: "FULL",
    keyword,
  });

  return context;
};

export const useGetUser = (who: RequestUser) => {
  const [globalPayload] = useGlobalPayload();

  const context = useFetch<ResponseUser>("post", `${globalPayload.commonDomain}/api/v1/crs-id-hrs/users/list`, {
    cmd: "1101",
    sid: globalPayload.sid,
    who,
    language: "ko",
  });

  return context;
};

export const useGetStorageEndpoint = () => {
  const [globalPayload] = useGlobalPayload();
  const [globalStorageId] = useGlobalStorageId();
  const { data } = useFetch<ResponseStorageEndpoint>("post", `${globalPayload.commonDomain}/api/v1/crs-file-storage/list`, {
    cmd: "1302",
    sid: globalPayload.sid,
    what: [{ strid: globalStorageId }],
  });
  const storageEndpoint = `${data?.data.result[0].endpoint ?? ""}/${data?.data.result[0].ver ?? ""}/crs-file-storage/object`;

  return storageEndpoint;
};
